import { Logger } from './logger';

import { Participants } from './data/participants';
import {
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason
} from './participant';
import { Messages } from './data/messages';
import { Message, MessageUpdatedEventArgs, MessageUpdateReason } from './message';

import { UriBuilder, isDeepEqual, parseToNumber } from './util';
import { Users } from './data/users';
import { Paginator } from './interfaces/paginator';
import { ConversationsDataSource } from './data/conversations';
import { McsClient } from '@twilio/mcs-client';

import { SyncClient } from 'twilio-sync';
import { TypingIndicator } from './services/typing-indicator';
import { Network } from './services/network';
import {
  validateTypesAsync, custom, literal, nonEmptyString, nonNegativeInteger, objectSchema, pureObject
} from '@twilio/declarative-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './command-executor';
import {
  AddParticipantRequest
} from './interfaces/commands/add-participant';
import { EditConversationRequest } from './interfaces/commands/edit-conversation';
import { ConversationResponse } from './interfaces/commands/conversation-response';
import { ParticipantResponse } from './interfaces/commands/participant-response';
import { EditNotificationLevelRequest } from './interfaces/commands/edit-notification-level';
import {
  EditLastReadMessageIndexRequest,
  EditLastReadMessageIndexResponse
} from './interfaces/commands/edit-last-read-message-index';
import { Limits } from './interfaces/limits';
import { MessageBuilder } from './message-builder';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type ConversationEvents = {
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
  updated: (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => void;
  removed: (conversation: Conversation) => void;
};

const log = Logger.scope('Conversation');

const fieldMappings = {
  lastMessage: 'lastMessage',
  attributes: 'attributes',
  createdBy: 'createdBy',
  dateCreated: 'dateCreated',
  dateUpdated: 'dateUpdated',
  friendlyName: 'friendlyName',
  lastConsumedMessageIndex: 'lastConsumedMessageIndex',
  notificationLevel: 'notificationLevel',
  sid: 'sid',
  status: 'status',
  uniqueName: 'uniqueName',
  state: 'state'
};

function parseTime(timeString) {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

export interface ConversationServices {
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

interface ConversationInternalState {
  uniqueName: string;
  status: ConversationStatus;
  attributes: any;
  createdBy?: string;
  dateCreated: Date;
  dateUpdated: Date;
  friendlyName?: string;
  lastReadMessageIndex: number | null;
  lastMessage?: LastMessage;
  notificationLevel?: NotificationLevel;
  state?: ConversationState;
}

interface ConversationDescriptor {
  channel: string;
  entityName: string;
  uniqueName: string;
  attributes: any;
  createdBy?: string;
  friendlyName?: string;
  lastConsumedMessageIndex: number;
  dateCreated: any;
  dateUpdated: any;
  notificationLevel?: NotificationLevel;
}

interface ConversationLinks {
  self: string;
  messages: string;
  participants: string;
}

/**
 * The reason for the `updated` event being emitted by a conversation.
 */
type ConversationUpdateReason =
  | 'attributes'
  | 'createdBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'friendlyName'
  | 'lastReadMessageIndex'
  | 'state'
  | 'status'
  | 'uniqueName'
  | 'lastMessage'
  | 'notificationLevel';

/**
 * The status of the conversation, relative to the client: whether
 * the conversation has been `joined` or the client is
 * `notParticipating` in the conversation.
 */
type ConversationStatus = 'notParticipating' | 'joined';

/**
 * The user's notification level for the conversation. Determines
 * whether the currently logged-in user will receive pushes for events
 * in this conversation. Can be either `muted` or `default`, where
 * `default` defers to the global service push configuration.
 */
type NotificationLevel = 'default' | 'muted';

/**
 * The state of the conversation.
 */
interface ConversationState {
  /**
   * The current state.
   */
  current: 'active' | 'inactive' | 'closed';

  /**
   * Date at which the latest conversation state update happened.
   */
  dateUpdated: Date;
}

interface ConversationUpdatedEventArgs {
  conversation: Conversation;
  updateReasons: ConversationUpdateReason[];
}

/**
 * Configuration for attaching a media file to a message.
 * These options can be passed to {@link Conversation.sendMessage} and
 * {@link MessageBuilder.addMedia}.
 */
interface SendMediaOptions {
  /**
   * Content type of media.
   */
  contentType: string;

  /**
   * Optional filename.
   */
  filename?: string;

  /**
   * Content to post.
   */
  media: string | Buffer | Blob;
}

/**
 * These options can be passed to {@link Conversation.sendMessage}.
 */
interface SendEmailOptions {
  /**
   *  Message subject. Ignored for media messages.
   */
  subject?: string;
}

/**
 * Information about the last message of a conversation.
 */
interface LastMessage {
  /**
   * Message's index.
   */
  index?: number;

  /**
   *  Message's creation date.
   */
  dateCreated?: Date;
}

/**
 * A conversation represents communication between multiple Conversations clients
 */
class Conversation extends ReplayEventEmitter<ConversationEvents> {
  /**
   * Unique system identifier of the conversation.
   */
  public readonly sid: string;
  public readonly links: ConversationLinks;

  private readonly configuration: Configuration;
  private readonly services: ConversationServices;
  private channelState: ConversationInternalState;
  private statusSource: ConversationsDataSource;

  private entityPromise: Promise<any>;
  private entityName: string;
  private entity: any;
  private messagesEntity: any;
  private participantsEntity: Participants;
  private readonly participants: any;

  /**
   * @internal
   */
  constructor(
    descriptor: ConversationDescriptor,
    sid: string,
    links: ConversationLinks,
    configuration: Configuration,
    services: ConversationServices
  ) {
    super();

    this.sid = sid;
    this.links = links;
    this.configuration = configuration;
    this.services = services;

    let attributes = descriptor.attributes || {};
    let createdBy = descriptor.createdBy;
    let dateCreated = parseTime(descriptor.dateCreated);
    let dateUpdated = parseTime(descriptor.dateUpdated);
    let friendlyName = descriptor.friendlyName || null;
    let lastReadMessageIndex =
      Number.isInteger(descriptor.lastConsumedMessageIndex) ? descriptor.lastConsumedMessageIndex : null;
    let uniqueName = descriptor.uniqueName || null;

    try {
      JSON.stringify(attributes);
    } catch (e) {
      throw new Error('Attributes must be a valid JSON object.');
    }

    this.entityName = descriptor.channel;
    this.channelState = {
      uniqueName,
      status: 'notParticipating',
      attributes,
      createdBy,
      dateCreated,
      dateUpdated,
      friendlyName,
      lastReadMessageIndex: lastReadMessageIndex
    };

    if (descriptor.notificationLevel) {
      this.channelState.notificationLevel = descriptor.notificationLevel;
    }

    const participantsLinks = {
      participants: this.links.participants
    };

    this.participants = new Map();
    this.participantsEntity = new Participants(
      this,
      this.participants,
      participantsLinks,
      this.configuration,
      this.services
    );
    this.participantsEntity.on('participantJoined', this.emit.bind(this, 'participantJoined'));
    this.participantsEntity.on('participantLeft', this.emit.bind(this, 'participantLeft'));
    this.participantsEntity.on('participantUpdated',
      (args: ParticipantUpdatedEventArgs) => this.emit('participantUpdated', args));

    this.messagesEntity = new Messages(this, configuration, services);
    this.messagesEntity.on('messageAdded', message => this._onMessageAdded(message));
    this.messagesEntity.on('messageUpdated',
      (args: MessageUpdatedEventArgs) => this.emit('messageUpdated', args));
    this.messagesEntity.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
  }

  /**
   * Fired when a participant has joined the conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - participant that joined the conversation
   * @event
   */
  static readonly participantJoined = 'participantJoined';

  /**
   * Fired when a participant has left the conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - participant that left the conversation
   * @event
   */
  static readonly participantLeft = 'participantLeft';

  /**
   * Fired when data of a participant has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Participant} `participant` - participant that has received the update
   *     * {@link ParticipantUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly participantUpdated = 'participantUpdated';

  /**
   * Fired when a new message has been added to the conversation.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been added
   * @event
   */
  static readonly messageAdded = 'messageAdded';

  /**
   * Fired when message is removed from the conversation's message list.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been removed
   * @event
   */
  static readonly messageRemoved = 'messageRemoved';

  /**
   * Fired when data of a message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} `message` - message that has received the update
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly messageUpdated = 'messageUpdated';

  /**
   * Fired when a participant has stopped typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant that has stopped typing
   * @event
   */
  static readonly typingEnded = 'typingEnded';

  /**
   * Fired when a participant has started typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant that has started typing
   * @event
   */
  static readonly typingStarted = 'typingStarted';

  /**
   * Fired when the data of the conversation has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Conversation} `conversation` - conversation that has received the update
   *     * {@link ConversationUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly updated = 'updated';

  /**
   * Fired when the conversation was destroyed or the currently-logged-in user has left private conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - conversation that has been removed
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Unique name of the conversation.
   */
  public get uniqueName(): string { return this.channelState.uniqueName; }

  /**
   * Status of the conversation.
   */
  public get status(): ConversationStatus { return this.channelState.status; }

  /**
   * Name of the conversation.
   */
  public get friendlyName(): string { return this.channelState.friendlyName; }

  /**
   * Date this conversation was last updated on.
   */
  public get dateUpdated(): any { return this.channelState.dateUpdated; }

  /**
   * Date this conversation was created on.
   */
  public get dateCreated(): any { return this.channelState.dateCreated; }

  /**
   * Identity of the user that created this conversation.
   */
  public get createdBy(): string { return this.channelState.createdBy; }

  /**
   * Custom attributes of the conversation.
   */
  public get attributes(): any { return this.channelState.attributes; }

  /**
   * Index of the last message the user has read in this conversation.
   */
  public get lastReadMessageIndex(): number | null { return this.channelState.lastReadMessageIndex; }

  /**
   * Last message sent to this conversation.
   */
  public get lastMessage(): LastMessage { return this.channelState.lastMessage; }

  /**
   * User notification level for this conversation.
   */
  public get notificationLevel(): NotificationLevel { return this.channelState.notificationLevel; }

  public get limits(): Limits { return this.configuration.limits; }

  /**
   * State of the conversation.
   */
  public get state(): ConversationState | undefined { return this.channelState.state; }

  /**
   * Load and subscribe to this conversation and do not subscribe to its participants and messages.
   * This or _subscribeStreams will need to be called before any events on conversation will fire.
   * @internal
   */
  _subscribe() {
    return this.entityPromise = this.entityPromise ??
      this.services.syncClient.document({ id: this.entityName, mode: 'open_existing' })
        .then(entity => {
          this.entity = entity;
          this.entity.on('updated', args => { this._update(args.data); });
          this.entity.on('removed', () => this.emit('removed', this));
          this._update(this.entity.data);
          return entity;
        })
        .catch(err => {
          this.entity = null;
          this.entityPromise = null;
          if (this.services.syncClient.connectionState != 'disconnected') {
            log.error('Failed to get conversation object', err);
          }
          log.debug('ERROR: Failed to get conversation object', err);
          throw err;
        });
  }

  /**
   * Load the attributes of this conversation and instantiate its participants and messages.
   * This or _subscribe will need to be called before any events on the conversation will fire.
   * This will need to be called before any events on participants or messages will fire
   * @internal
   */
  async _subscribeStreams() {
    try {
      await this._subscribe();
      log.trace('_subscribeStreams, this.entity.data=', this.entity.data);
      const messagesObjectName = this.entity.data.messages;
      const rosterObjectName = this.entity.data.roster;
      await Promise.all([
        this.messagesEntity.subscribe(messagesObjectName),
        this.participantsEntity.subscribe(rosterObjectName)
      ]);
    } catch (err) {
      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error('Failed to subscribe on conversation objects', this.sid, err);
      }
      log.debug('ERROR: Failed to subscribe on conversation objects', this.sid, err);
      throw err;
    }
  }

  /**
   * Stop listening for and firing events on this conversation.
   * @internal
   */
  async _unsubscribe() {
    if (this.entity) {
      await this.entity.close();
      this.entity = null;
      this.entityPromise = null;
    }

    return Promise.all([
      this.participantsEntity.unsubscribe(),
      this.messagesEntity.unsubscribe()
    ]);
  }

  /**
   * Set conversation status.
   * @internal
   */
  _setStatus(status: ConversationStatus, source: ConversationsDataSource) {
    this.statusSource = source;

    if (this.channelState.status === status) { return; }

    this.channelState.status = status;

    if (status === 'joined') {
      this._subscribeStreams()
        .catch(err => {
          log.debug('ERROR while setting conversation status ' + status, err);
          if (this.services.syncClient.connectionState !== 'disconnected') {
            throw err;
          }
        });
    } else if (this.entityPromise) {
      this._unsubscribe().catch(err => {
        log.debug('ERROR while setting conversation status ' + status, err);
        if (this.services.syncClient.connectionState !== 'disconnected') {
          throw err;
        }
      });
    }
  }

  /**
   * Get the source of the conversation update.
   * @internal
   */
  _statusSource(): ConversationsDataSource {
    return this.statusSource;
  }

  private static preprocessUpdate(update, conversationSid) {
    try {
      if (typeof update.attributes === 'string') {
        update.attributes = JSON.parse(update.attributes);
      } else if (update.attributes) {
        JSON.stringify(update.attributes);
      }
    } catch (e) {
      log.warn('Retrieved malformed attributes from the server for conversation: ' + conversationSid);
      update.attributes = {};
    }

    try {
      if (update.dateCreated) {
        update.dateCreated = new Date(update.dateCreated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateCreated from the server for conversation: ' + conversationSid);
      delete update.dateCreated;
    }

    try {
      if (update.dateUpdated) {
        update.dateUpdated = new Date(update.dateUpdated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateUpdated from the server for conversation: ' + conversationSid);
      delete update.dateUpdated;
    }

    try {
      if (update.lastMessage && update.lastMessage.timestamp) {
        update.lastMessage.timestamp = new Date(update.lastMessage.timestamp);
      }
    } catch (e) {
      log.warn('Retrieved malformed lastMessage.timestamp from the server for conversation: ' + conversationSid);
      delete update.lastMessage.timestamp;
    }
  }

  /**
   * Update the local conversation object with new values.
   * @internal
   */
  _update(update) {
    log.trace('_update', update);

    Conversation.preprocessUpdate(update, this.sid);
    const updateReasons = new Set<ConversationUpdateReason>();

    for (const key of Object.keys(update)) {
      const localKey = fieldMappings[key];

      if (!localKey) {
        continue;
      }

      switch (localKey) {
        case fieldMappings.status:
          if (!update.status || update.status === 'unknown'
            || this.channelState.status === update.status) {
            break;
          }

          this.channelState.status = update.status;
          updateReasons.add(localKey);

          break;
        case fieldMappings.attributes:
          if (isDeepEqual(this.channelState.attributes, update.attributes)) {
            break;
          }

          this.channelState.attributes = update.attributes;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastConsumedMessageIndex:
          if (update.lastConsumedMessageIndex === undefined
            || update.lastConsumedMessageIndex === this.channelState.lastReadMessageIndex) {
            break;
          }

          this.channelState.lastReadMessageIndex = update.lastConsumedMessageIndex;
          updateReasons.add('lastReadMessageIndex');

          break;
        case fieldMappings.lastMessage:
          if (this.channelState.lastMessage && !update.lastMessage) {
            delete this.channelState.lastMessage;
            updateReasons.add(localKey);

            break;
          }

          this.channelState.lastMessage = this.channelState.lastMessage || {};

          if (update.lastMessage?.index !== undefined
            && update.lastMessage.index !== this.channelState.lastMessage.index) {
            this.channelState.lastMessage.index = update.lastMessage.index;
            updateReasons.add(localKey);
          }

          if (update.lastMessage?.timestamp !== undefined
            && this.channelState.lastMessage?.dateCreated?.getTime() !== update.lastMessage.timestamp.getTime()) {
            this.channelState.lastMessage.dateCreated = update.lastMessage.timestamp;
            updateReasons.add(localKey);
          }

          if (isDeepEqual(this.channelState.lastMessage, {})) {
            delete this.channelState.lastMessage;
          }

          break;
        case fieldMappings.state:
          const state = update.state || undefined;

          if (state !== undefined) {
            state.dateUpdated = new Date(state.dateUpdated);
          }

          if (isDeepEqual(this.channelState.state, state)) {
            break;
          }

          this.channelState.state = state;
          updateReasons.add(localKey);

          break;
        default:
          const isDate = update[key] instanceof Date;
          const keysMatchAsDates = isDate && this.channelState[localKey]?.getTime() === update[key].getTime();
          const keysMatchAsNonDates = !isDate && this[localKey] === update[key];

          if (keysMatchAsDates || keysMatchAsNonDates) {
            break;
          }

          this.channelState[localKey] = update[key];
          updateReasons.add(localKey);
      }
    }

    if (updateReasons.size > 0) {
      this.emit('updated', { conversation: this, updateReasons: [...updateReasons] });
    }
  }

  /**
   * @internal
   */
  private _onMessageAdded(message) {
    for (let participant of this.participants.values()) {
      if (participant.identity === message.author) {
        participant._endTyping();
        break;
      }
    }
    this.emit('messageAdded', message);
  }

  private async _setLastReadMessageIndex(index: number | null): Promise<number> {
    const result = await this.services.commandExecutor.mutateResource<
      EditLastReadMessageIndexRequest,
      EditLastReadMessageIndexResponse
      >(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        last_read_message_index: index
      }
    );

    return result.unread_messages_count;
  }

  /**
   * Add a participant to the conversation by its identity.
   * @param identity Identity of the Client to add.
   * @param attributes Attributes to be attached to the participant.
   */
  @validateTypesAsync(
    nonEmptyString,
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)]
  )
  async add(identity: string, attributes?: any): Promise<void> {
    return this.participantsEntity.add(identity, attributes);
  }

  /**
   * Add a non-chat participant to the conversation.
   * @param proxyAddress Proxy (Twilio) address of the participant.
   * @param address User address of the participant.
   * @param attributes Attributes to be attached to the participant.
   */
  @validateTypesAsync(
    nonEmptyString,
    nonEmptyString,
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)]
  )
  async addNonChatParticipant(proxyAddress: string, address: string, attributes?: any): Promise<void> {
    return this.participantsEntity.addNonChatParticipant(proxyAddress, address, attributes);
  }

  /**
   * Advance the conversation's last read message index to the current read horizon.
   * Rejects if the user is not a participant of the conversation.
   * Last read message index is updated only if the new index value is higher than the previous.
   * @param index Message index to advance to.
   * @return Resulting unread messages count in the conversation.
   */
  @validateTypesAsync(nonNegativeInteger)
  async advanceLastReadMessageIndex(index: number): Promise<number> {
    await this._subscribeStreams();

    if (index < this.lastReadMessageIndex) {
      return await this._setLastReadMessageIndex(this.lastReadMessageIndex);
    }

    return await this._setLastReadMessageIndex(index);
  }

  /**
   * Delete the conversation and unsubscribe from its events.
   */
  async delete(): Promise<Conversation> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      this.links.self,
    );

    return this;
  }

  /**
   * Get the custom attributes of this Conversation.
   */
  async getAttributes(): Promise<any> {
    await this._subscribe();
    return this.attributes;
  }

  /**
   * Returns messages from the conversation using the paginator interface.
   * @param pageSize Number of messages to return in a single chunk. Default is 30.
   * @param anchor Index of the newest message to fetch. Default is from the end.
   * @param direction Query direction. By default it queries backwards
   * from newer to older. The `"forward"` value will query in the opposite direction.
   * @return A page of messages.
   */
  @validateTypesAsync(
    ['undefined', nonNegativeInteger],
    ['undefined', nonNegativeInteger],
    ['undefined', literal('backwards', 'forward')]
  )
  async getMessages(pageSize?: number, anchor?: number, direction?: 'backwards' | 'forward'): Promise<Paginator<Message>> {
    await this._subscribeStreams();
    return this.messagesEntity.getMessages(pageSize, anchor, direction);
  }

  /**
   * Get a list of all the participants who are joined to this conversation.
   */
  async getParticipants(): Promise<Participant[]> {
    await this._subscribeStreams();
    return this.participantsEntity.getParticipants();
  }

  /**
   * Get conversation participants count.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Conversations system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  async getParticipantsCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations)
      .path(this.sid)
      .build();
    const response = await this.services.network.get(url);

    return response.body.participants_count;
  }

  /**
   * Get a participant by its SID.
   * @param participantSid Participant SID.
   */
  @validateTypesAsync(nonEmptyString)
  async getParticipantBySid(participantSid: string): Promise<Participant> {
    return this.participantsEntity.getParticipantBySid(participantSid);
  }

  /**
   * Get a participant by its identity.
   * @param identity Participant identity.
   */
  @validateTypesAsync(nonEmptyString)
  async getParticipantByIdentity(identity: string): Promise<Participant> {
    return this.participantsEntity.getParticipantByIdentity(identity);
  }

  /**
   * Get the total message count in the conversation.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Conversations system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  async getMessagesCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations)
      .path(this.sid)
      .build();
    const response = await this.services.network.get(url);

    return response.body.messages_count;
  }

  /**
   * Get unread messages count for the user if they are a participant of this conversation.
   * Rejects if the user is not a participant of the conversation.
   *
   * Use this method to obtain the number of unread messages together with
   * {@link Conversation.updateLastReadMessageIndex} instead of relying on the
   * message indices which may have gaps. See {@link Message.index} for details.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Conversations system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  async getUnreadMessagesCount(): Promise<number | null> {
    const url = new UriBuilder(this.configuration.links.myConversations)
      .path(this.sid)
      .build();
    const response = await this.services.network.get(url);

    if (response.body.conversation_sid !== this.sid) {
      throw new Error(
        'Conversation was not found in the user conversations list'
      );
    }

    const unreadMessageCount = response.body.unread_messages_count;

    if (typeof unreadMessageCount === 'number') {
      return unreadMessageCount;
    }

    return null;
  }

  /**
   * Join the conversation and subscribe to its events.
   */
  async join(): Promise<Conversation> {
    await this.services.commandExecutor.mutateResource<AddParticipantRequest, ParticipantResponse>(
      'post',
      this.links.participants,
      {
        identity: this.configuration.userIdentity
      }
    );

    return this;
  }

  /**
   * Leave the conversation.
   */
  async leave(): Promise<Conversation> {
    if (this.channelState.status === 'joined') {
      await this.services.commandExecutor.mutateResource(
        'delete',
        `${this.links.participants}/${this.configuration.userIdentity}`
      );
    }

    return this;
  }

  /**
   * Remove a participant from the conversation. When a string is passed as the
   * argument, it will assume that the string is an identity or SID.
   * @param participant Identity, SID or the participant object to remove.
   */
  @validateTypesAsync([nonEmptyString, Participant])
  async removeParticipant(participant: string | Participant): Promise<void> {
    await this.participantsEntity.remove(
      typeof participant === 'string'
        ? participant
        : participant.sid
    );
  }

  /**
   * Send a message to the conversation.
   * @param message Message body for the text message,
   * `FormData` or {@link SendMediaOptions} for media content. Sending FormData is supported only with the browser engine.
   * @param messageAttributes Attributes for the message.
   * @param emailOptions Email options for the message.
   * @return Index of the new message.
   */
  @validateTypesAsync(
    [
      'string',
      literal(null),
      // Wrapping it into a custom rule is necessary because the FormData class is not available on initialization.
      custom((value) => [value instanceof FormData, 'an instance of FormData']),
      objectSchema('media options', {
        contentType: nonEmptyString,
        media: custom((value) => {
          let isValid = (typeof value === 'string' && value.length > 0) || value instanceof Uint8Array || value instanceof ArrayBuffer;

          if (typeof Blob === 'function') {
            isValid = isValid || value instanceof Blob;
          }

          return [
            isValid,
            'a non-empty string, an instance of Buffer or an instance of Blob'
          ];
        })
      })
    ],
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)],
    ['undefined', literal(null), objectSchema('email attributes', {
      subject: [nonEmptyString, 'undefined']
    })]
  )
  async sendMessage(message: string | FormData | SendMediaOptions | null, messageAttributes?: any,
                    emailOptions?: SendEmailOptions): Promise<number> {
    if (typeof message === 'string' || message === null) {
      let response = await this.messagesEntity.send(message, messageAttributes, emailOptions);
      return parseToNumber(response.index);
    }

    let response = await this.messagesEntity.sendMedia(message, messageAttributes, emailOptions);
    return parseToNumber(response.index);
  }

  /**
   * New interface to prepare for sending a message.
   * Use instead of `sendMessage`.
   */
  public prepareMessage(): MessageBuilder {
    return new MessageBuilder(this.limits, this.messagesEntity);
  }

  /**
   * Set last read message index of the conversation to the index of the last known message.
   * @return Resulting unread messages count in the conversation.
   */
  public async setAllMessagesRead(): Promise<number> {
    await this._subscribeStreams();

    let messagesPage = await this.getMessages(1);

    if (messagesPage.items.length > 0) {
      return this.advanceLastReadMessageIndex(messagesPage.items[0].index);
    }

    return Promise.resolve(0);
  }

  /**
   * Set all messages in the conversation unread.
   * @return Resulting unread messages count in the conversation.
   */
  public async setAllMessagesUnread(): Promise<number> {
    await this._subscribeStreams();
    return await this._setLastReadMessageIndex(null);
  }

  /**
   * Set user notification level for this conversation.
   * @param notificationLevel New user notification level.
   */
  @validateTypesAsync(literal('default', 'muted'))
  async setUserNotificationLevel(notificationLevel: NotificationLevel): Promise<void> {
    await this.services.commandExecutor.mutateResource<EditNotificationLevelRequest>(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        notification_level: notificationLevel
      }
    );
  }

  /**
   * Send a notification to the server indicating that this client is currently typing in this conversation.
   * Typing ended notification is sent after a while automatically, but by calling this method again you ensure that typing ended is not received.
   */
  typing(): Promise<void> {
    return this.services.typingIndicator.send(this.sid);
  }

  /**
   * Update the attributes of the conversation.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Conversation> {
    await this.services.commandExecutor.mutateResource<EditConversationRequest, ConversationResponse>(
      'post',
      this.links.self,
      {
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );

    return this;
  }

  /**
   * Update the friendly name of the conversation.
   * @param friendlyName New friendly name.
   */
  @validateTypesAsync(['string'])
  async updateFriendlyName(friendlyName: string): Promise<Conversation> {
    if (this.channelState.friendlyName !== friendlyName) {
      await this.services.commandExecutor.mutateResource<EditConversationRequest, ConversationResponse>(
        'post',
        this.links.self,
        { friendly_name: friendlyName }
      );
    }

    return this;
  }

  /**
   * Set the last read message index to the current read horizon.
   * @param index Message index to set as last read.
   * If null is provided, then the behavior is identical to {@link Conversation.setAllMessagesUnread}.
   * @returns Resulting unread messages count in the conversation.
   */
  @validateTypesAsync([literal(null), nonNegativeInteger])
  async updateLastReadMessageIndex(index: number | null): Promise<number> {
    await this._subscribeStreams();
    return this._setLastReadMessageIndex(index);
  }

  /**
   * Update the unique name of the conversation.
   * @param uniqueName New unique name for the conversation. Setting unique name to null removes it.
   */
  @validateTypesAsync(['string', literal(null)])
  async updateUniqueName(uniqueName: string | null): Promise<Conversation> {
    if (this.channelState.uniqueName !== uniqueName) {
      if (!uniqueName) {
        uniqueName = '';
      }

      await this.services.commandExecutor.mutateResource<EditConversationRequest, ConversationResponse>(
        'post',
        this.links.self,
        {
          unique_name: uniqueName
        }
      );
    }

    return this;
  }
}

export {
  ConversationDescriptor,
  Conversation,
  ConversationUpdateReason,
  ConversationStatus,
  NotificationLevel,
  ConversationState,
  ConversationUpdatedEventArgs,
  SendMediaOptions,
  SendEmailOptions,
  LastMessage
};
